
import { Component, Vue, Prop } from "vue-property-decorator";
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import PopUp from "@/components/PopUp.vue";
import HrmService from '@/services/hrm-service'



@Component({
    components: {
        VueCal,
        PopUp,
    }
})
export default class GazetHolidays extends Vue {
    public document_file: any = null;
    public holidays: any = null;
    public holidays_obj: any = []
    public leave_obj: any = {}


    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }

    // modal end

    mounted() {
        this.retreive()

    }
    retreive() {
        HrmService.getLeaveHolidaysPlanner().then((response) => {
            this.holidays_obj = response.data.holidays
            this.leave_obj = response.data.leaves
        })
    }




    // events = [
    //     {
    //         start: '2023-09-21 09:00',
    //         end: '2023-09-21 17:00',
    //         title: 'Need to go shopping',
    //         class: 'leisure',

    //     },
    //     {
    //         start: '2023-09-21 09:00',
    //         end: '2023-09-21 17:00',
    //         title: 'Golf with John',
    //         class: 'sport',

    //     },
    //     {
    //         start: '2023-09-22 09:00',
    //         end: '2023-09-22 17:00',
    //         title: 'Dad\'s birthday!',
    //         class: 'sport',

    //     },
    //     {
    //         start: '2023-09-22 00:00',
    //         end: '2023-09-22 23:00',
    //         title: 'LUNCH',
    //         class: 'lunch',
    //         background: true
    //     },
    //     {
    //         start: '2023-09-21 00:00',
    //         end: '2023-09-21 23:00',
    //         title: 'LUNCH',
    //         class: 'lunch',
    //         background: true
    //     },
    // ]
    // specialHours = {
    //     1: {
    //         from: 480,
    //         to: 1020,
    //         class: 'off',
    //         label: 'Doctor 1Full day shift',

    //     },
    // }

    createHoliday() {
        const holidayExists = this.holidays_obj.some((holiday: any) => {
            const holidayStartDate = holiday.start.split(" ")[0];
            return holidayStartDate === this.holidays;
        });
        if (holidayExists) {
            this.showModal("This day is already a holiday", [], "error");
            return;
        }
        HrmService.createGazzetedHolidays(this.document_file, this.holidays).then((response) => {
            if (response.data) {
                this.showModal("Successfully Created", [], 'success')
                this.holidays = null
                this.document_file = null
                this.retreive();
            }
            else {
                // console.log(response)
                this.holidays = null
                this.document_file = null
            }
        }).catch((err) => {
            if (err.response && err.response.status === 400) {
                // Handle the 400 Bad Request error
                this.showModal("", err.response.data, "errorArray");
                this.holidays = null
                this.document_file = null
            } else {
                // Handle other types of errors (e.g., network issues, server errors)
                console.error('Unexpected error:', err);
                this.showModal('An unexpected error occurred', [], "error");
                this.holidays = null
                this.document_file = null
            }
        })
    }
}
