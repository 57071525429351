
import { Component, Vue, Prop } from "vue-property-decorator";
import hrmService from "@/services/hrm-service";
import utils from "@/utils/utils";
import PopUp from "@/components/PopUp.vue";
import { BvModal } from "bootstrap-vue";


interface Allocation {
    from_year: number;
    to_year: number;
    days: number;
}
@Component({
    components: {
        PopUp,

    },
})
export default class EntitlementTypes extends Vue {
    @Prop({ default: 'types' }) activeState!: String;
    mounted() {
        this.retreive()
        this.get_leave_types_data()


    }
    public currentPage = 1;
    leave_type_opt: any = []
    // leave_type_opt_for_update: any = []

    error: any = ""

    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    // modal end



    monthly_entitlement = Array.from({ length: 12 }, (_, index) => ({
        month: index + 1,
        days: 0,
    }))
    method = [
        "Earned Immediately",
        "Prorated",
        "Custom Monthly Allocation",
        "Custom Yearly Allocation",
        "Not Required",
    ];
    period = ["Monthly", "Daily"];
    earn_at = ["End of Accrual Period", "Start of Accrual Period"];
    leave_settings: any = {} // change
    newAllocation = {
        from_year: 0,
        to_year: 0,
        days: 0
    } as Allocation // Explicitly set the type using "as"
    allocations = [
        {
            from_year: 0,
            to_year: 0,
            days: 0
        }
    ] as Allocation[] // Explicitly set the type using "as"
    addAllocation() {
        const lastAllocation = this.allocations[this.allocations.length - 1];
        const newFromYear = lastAllocation ? lastAllocation.to_year : 0;

        this.allocations.push({ ...this.newAllocation, from_year: newFromYear });
        this.newAllocation.from_year = 0;
        this.newAllocation.to_year = 0;
        this.newAllocation.days = 0;
    }
    removeAllocation() {
        this.allocations.splice(this.allocations.length - 1, 1);
    }
    clearAllocations() {
        this.allocations = [{
            from_year: 0,
            to_year: 0,
            days: 0
        }];
    }

    convertDaysToNumber(entry: any) {
        entry.days = parseInt(entry.days);
    }
    validateDataForYear(id: number) {
        if (id >= 0 && id < this.allocations.length - 1) {
            this.allocations[id + 1]['from_year'] = this.allocations[id]['to_year'];
        }
    }
    items: any = []
    forUpdate = false
    fields = [
        { key: "title", sortable: true },
        { key: "method" },
        { key: "accural_period" },
        { key: "earned_at" },
        { key: "monthly_entitlement", label: "Monthly Entitlement" }, // Custom label
        { key: "yearly_entitlement", label: "Yearly Entitlement" },   // Custom label
    ]
    get rows() {
        return this.items.length;
    }
    earn_settings: any = {
        title: "",
        method: "",
        accural_period: "",
        earned_at: "",

    };
    onRowClicked(data: any) {
        // console.log(data['method'])
        this.forUpdate = true;

        this.earn_settings.leave_type = data.leave_type
        this.earn_settings['id'] = data['id']
        this.earn_settings['title'] = data['title']
        this.earn_settings['method'] = data['method']
        this.earn_settings['accural_period'] = data['accural_period']
        this.earn_settings['earned_at'] = data['earned_at']
        if (this.earn_settings['method'] == 'Custom Monthly Allocation') {
            this.monthly_entitlement = data.monthly_entitlement
        }
        else if (this.earn_settings['method'] == 'Custom Yearly Allocation') {
            this.allocations = data.yearly_entitlement
        }
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-earn");
    }
    viewPolicy(id: number) { }
    methodCheck() {
        this.earn_settings.accural_period = ""
        this.earn_settings.earned_at = ""
        this.clearAllocations()
        this.monthly_entitlement = Array.from({ length: 12 }, (_, index) => ({
            month: index + 1,
            days: 0,
        }))
    }
    daysNotMoreThenAssign(data: any) {
        if (this.earn_settings.method == "Custom Monthly Allocation") {
            const selectedOption = this.leave_type_opt.find((option: any) => option.id === this.earn_settings.leave_type);
            if (selectedOption) {
                let totalLeaves = parseInt(selectedOption.no_of_leaves)
                let entitlement = this.earn_settings['monthly_entitlement']
                let _totaldays = 0
                entitlement.forEach((element: any) => {
                    _totaldays += parseInt(element.days)
                });
                if (_totaldays <= totalLeaves) {
                    return false
                }
                else {
                    return true
                }
            }
        }
        else if (this.earn_settings.method == "Custom Yearly Allocation") {
            const selectedOption = this.leave_type_opt.find((option: any) => option.id === this.earn_settings.leave_type);
            if (selectedOption) {
                let totalLeaves = parseInt(selectedOption.no_of_leaves)
                let entitlement = this.earn_settings['yearly_entitlement']
                let _totaldays = 0
                entitlement.forEach((element: any) => {
                    if (_totaldays <= element.days) {
                        _totaldays = parseInt(element.days)
                    }
                });
                if (_totaldays <= totalLeaves) {

                    return false
                }
                else {
                    return true
                }
            }
        }
        else return false
    }
    save() {
        if (this.earn_settings.method == "Custom Monthly Allocation") {
            this.earn_settings['monthly_entitlement'] = this.monthly_entitlement

        }
        if (this.earn_settings.method == "Custom Yearly Allocation") {
            this.earn_settings['yearly_entitlement'] = this.allocations
        }

        this.error = ""
        if (this.daysNotMoreThenAssign(this.earn_settings)) {
            this.error = "You allotted more days than are assigned in the leave type."
        }
        else {
            const error_list = utils.getEmptyKeys(this.earn_settings, ['title', 'method'])
            if (error_list.length > 0) {
                this.error = utils.generateErrorMessage(error_list)
            }
            else {

                if (!this.forUpdate) {

                    hrmService.createEntitlementPolicy(this.earn_settings).then((response) => {
                        this.retreive()
                        this.forUpdate = false
                        this.clear()
                        this.closeSideBar()
                        this.showModal("Succesfully created", [], "success");

                    }).catch((err) => {
                        if (err.response && err.response.status === 400) {
                            // Handle the 400 Bad Request error
                            this.showModal("", err.response.data, "errorArray");
                        } else {
                            // Handle other types of errors (e.g., network issues, server errors)
                            console.error('Unexpected error:', err);
                            this.showModal('An unexpected error occurred', [], "error");
                        }
                    })
                } else {
                    hrmService.updateEntitlementPolicy(this.earn_settings, this.earn_settings.id).then((response) => {
                        this.retreive()
                        this.forUpdate = false
                        this.clear()
                        this.closeSideBar()
                        this.showModal("Succesfully updated", [], "success");



                    }).catch((err) => {
                        if (err.response && err.response.status === 400) {
                            // Handle the 400 Bad Request error
                            this.showModal("", err.response.data, "errorArray");
                        } else {
                            // Handle other types of errors (e.g., network issues, server errors)
                            console.error('Unexpected error:', err);
                            this.showModal('An unexpected error occurred', [], "error");
                        }
                    })
                }
            }
        }

    }

    retreive() {
        hrmService.getEntitlementPolicy().then((response) => {
            this.items = response.data
        })
    }
    get_leave_types_data() {
        hrmService.getLeaveType().then((resp) => {
            this.leave_type_opt = resp.data
        })
    }
    // get only leave types which dont have policies                                                    Dont Remove usefull in future
    // get_leave_types_data() {
    //     hrmService.getLeaveType().then((resp) => {
    //         const leaveTypeData = resp.data;

    //         // Extract unique leave_type values from this.items and store them in an array
    //         const leaveTypeValues = this.items.map((item: any) => item.leave_type);

    //         // Filter leaveTypeData to include only those objects with leave_type not in leaveTypeValues
    //         this.leave_type_opt = leaveTypeData.filter((leaveType: any) => {
    //             return !leaveTypeValues.includes(leaveType.id);
    //         });
    //         // this.leave_type_opt = resp.data;
    //         // console.log(this.items)

    //         console.log(this.leave_type_opt);
    //     });
    // }



    closeSideBar() {
        const entitlementSidebar = this.$refs.entitlementSidebar as BvModal | undefined;
        if (entitlementSidebar) {
            entitlementSidebar.hide("sidebar-variant-earn");
        }
    }

    setTitle() {
        const selectedOption = this.leave_type_opt.find((option: any) => option.id === this.earn_settings.leave_type);
        if (selectedOption) {
            this.earn_settings.title = selectedOption.title + " Policy"
        }
    }
    clear() {
        this.forUpdate = false
        this.monthly_entitlement = Array.from({ length: 12 }, (_, index) => ({
            month: index + 1,
            days: 0,
        }))
        this.allocations = [
            {
                from_year: 0,
                to_year: 0,
                days: 0
            }
        ]
        this.earn_settings = {
            title: "",
            method: "",
            accural_period: "",
            period: "",
            earned_at: "",
        }
    }

}
