
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SidebarHrReports extends Vue {
  @Prop({ type: Function, default: () => { } })
  handleActive!: Function;
  @Prop({ default: "" }) activeState!: "";


  navlist = [
    {
      item: "Leave Types",
      node: "types",
      route: "hrm/leave-managment/settings/type",
    },
    {
      item: "Entitlement Policy's",
      node: "earn",
      route: "hrm/leave-managment/settings/earning-policy",
    },
    // {
    //   item: "Shedule",
    //   node: "sheduled",
    //   route: "hrm/leave-managment/settings/shedule",
    // },
    // {
    //   item: "Employee Leaves",
    //   node: "emp-leaves",
    //   route: "hrm/leave-managment/settings/emplyee-leaves",
    // },
    // {
    //   item: "Approval Workflow",
    //   node: "workflow",
    //   route: "hrm/leave-managment/settings/approval-workflow",
    // },
    // {
    //   item: "Alot Leaves",
    //   node: "leaves",
    //   route: "hrm/leave-managment/settings/leaves",
    // },
    {
      item: "Leave Applications",
      node: "application",
      route: "hrm/leave-managment/settings/leaveapplications",
    },
    {
      item: "Employee Leaves and Holidays",
      node: "holiday",
      route: "hrm/leave-managment/settings/gazetholidays",
    }
  ];
  //   public data = [
  //     {
  //       item: "Employee",
  //       visibility: false,
  //       collapse: "collapse-1",
  //       child: [
  //         { item: "Employee", route: "/" },
  //         { item: "Salary", route: "/" },
  //         { item: "Iqama", route: "/" },
  //         { item: "Driving License", route: "/" },
  //         // { item: "Balance Sheet", route: "/balancesheet" },
  //         // { item: "Profit & Loss", route: "/profitloss" },
  //       ],
  //     },
  //   ];
  //   mounted() {
  //     let index = this.data.findIndex((x) => {
  //       return x.item === this.$route.name;
  //     });
  //     if (index >= 0) {
  //       this.data[index]["visibility"] = true;
  //     }
  //   }
}
