
import { Component, Vue, Prop } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import LeaveManagmentSidebar from "@/components/leave-managment-sidebar.vue"; // @ is an alias to /src
import EntitlementTypes from './entitlementtypes.vue'
import LeaveTypes from "./leavetypes.vue";
import ApprovalWorkflow from './approvalworkflow.vue';
import AlotLeaves from "./alotleaves.vue";
import LeaveApplications from './leaveapplications.vue'
import GazetHolidays from "./gazetholidays.vue";

@Component({
  components: {
    Header,
    LeaveManagmentSidebar,
    LeaveTypes,
    EntitlementTypes,
    ApprovalWorkflow,
    // AlotLeaves,
    LeaveApplications,
    GazetHolidays,
  },
})
export default class Leave extends Vue {
  activeState: string = "types";
  handleActive(item: any) {
    // console.log(item);
    this.activeState = item;
  }



}
