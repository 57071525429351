
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
    components: {

    },
})
export default class ApprovlWorkflow extends Vue {
    items = [
        {
            name: "Earn/Annual",
            no_of_leaves: 14,
            calendar_type: "Joined Date",
            nature: "CF/ Adjust in Salary",
            comments: "",
        },
        {
            name: "Casual Leave",
            no_of_leaves: 10,
            calendar_type: "Yearly",
            nature: "Vanish",
            comments: "",
        },
        {
            name: "Earn/Annual",
            no_of_leaves: 16,
            calendar_type: "Yearly",
            nature: "Vanish",
            comments: "",
        },
        {
            name: "Hajj",
            no_of_leaves: 45,
            calendar_type: "Joined Date",
            nature: "Vanish",
            comments: "",
        },
        {
            name: "Maternity",
            no_of_leaves: 60,
            calendar_type: "Joined Date",
            nature: "Vanish",
            comments: "",
        },
    ];
}
