
import { Component, Vue, Prop } from "vue-property-decorator";
import HrmService from '@/services/hrm-service'
import PopUp from "@/components/PopUp.vue";
import { BvModal } from "bootstrap-vue";




@Component({
    components: {
        PopUp,

    },
})
export default class LeaveApplications extends Vue {
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    // modal end

    items: any = []
    status = ""
    remarks = ""
    currentPage = 1
    forUpdate = false
    emp_data: any = {}
    searchSelected: string = ""

    get rows() {
        return this.items.length;
    }
    closeSideBar() {
        const simpleEmployeeSidebar = this.$refs.simpleEmployeeSidebar as BvModal | undefined;
        if (simpleEmployeeSidebar) {
            simpleEmployeeSidebar.hide("sidebar-variant-emp-leave-application");
        }
        const bulkEmployeeSidebar = this.$refs.bulkEmployeeSidebar as BvModal | undefined;
        if (bulkEmployeeSidebar) {
            bulkEmployeeSidebar.hide("sidebar-variant-leave-application");
        }
        this.status = ""
        this.remarks = ''
        this.emp_data = {}
    }
    onRowClicked(data: any) {
        this.emp_data = data
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-emp-leave-application");

    }
    buttonText() {
        if (this.emp_data.leave_request__id !== null) {
            return this.emp_data.status === "Rejected" ? "Update Reject" :
                this.emp_data.status === "Accepted" ? "Update Accept" :
                    "Update Not Accept";
        } else {
            return this.emp_data.status === "Rejected" ? "Reject" :
                this.emp_data.status === "Accepted" ? "Accept" :
                    "Not Accept";
        }
    }
    selectAll = false

    selectAllRows() {

        this.items.forEach((item: any) => {
            item['select'] = this.selectAll;
        });

    }
    rowSelected(data: any) {
        // console.log(data)
    }
    method = [
        "Rejected",
        "Accepted",
        "Not Accepted"
    ]
    searchOpt = [
        { value: null, text: 'Please select an option' },
        { value: "New", text: 'New' },
        { value: "Accepted", text: "Accepted" },
        { value: "Not Accepted", text: "Not Accepted" },
        { value: "Rejected", text: "Rejected" },
    ]

    fields = [
        {
            key: "employee_code"
        },
        {
            key: "employee_title"
        },
        {
            key: "start_date"
        },
        {
            key: "end_date"
        },
        {
            key: "leave_type__title", label: "Leave Type"
        },
        {
            key: "leave_type__no_of_leaves", label: "Leave Type No of Leaves"
        },
        {
            key: "remaining_balance", label: "Remaining Balance"
        },
        {
            key: "balances",
        },
        {
            key: "before_applied_leave_balance"
        },
        {
            key: "reason"
        },
        {
            key: "created_at", label: "Applied On"
        },
        {
            key: "updated_at", label: "Update On"
        },
        {
            key: "leave_request__status"
        },
        {
            key: "leave_request__created_at", label: "Managed At",
        },
        {
            key: "select"
        }
    ]
    manage() { }
    mounted() {
        this.retrieve("New")
        this.searchSelected = "New"
    }
    handleApproved() {
        // console.log(this.items)
    }
    retrieve(searchSelected: string) {

        HrmService.get_employee_leave_applications(searchSelected).then((resp) => {
            this.items = resp.data.result
        }).catch((err) => {
            console.log(err)
        })
    }
    handleApplications(type: string, data: any) {
        if (data.leave_request__status !== "Accepted") {
            if (data.leave_request__id === null || data.leave_request__id === undefined) {
                HrmService.handleLeaveApplications(data, type).then((response) => {
                    // console.log(response.data.result)
                    this.showModal("Succesfully created", [], "success");
                    this.closeSideBar()
                    this.retrieve("New")
                    this.searchSelected = "New"
                }).catch((err) => {
                    if (err.response && err.response.status === 400) {
                        // Handle the 400 Bad Request error
                        this.showModal("", err.response.data, "errorArray");
                    } else {
                        // Handle other types of errors (e.g., network issues, server errors)
                        console.error('Unexpected error:', err);
                        this.showModal('An unexpected error occurred', [], "error");
                    }
                })
            } else if (data.leave_request__id !== null || data.leave_request__id !== undefined) {
                HrmService.updateLeaveApplications(data, type, data.leave_request__id).then((response) => {
                    // console.log(response.data.result)
                    this.showModal("Succesfully Updated", [], "success");
                    this.closeSideBar()
                    this.retrieve("New")
                    this.searchSelected = "New"
                }).catch((err) => {
                    if (err.response && err.response.status === 400) {
                        // Handle the 400 Bad Request error
                        this.showModal("", err.response.data, "errorArray");
                    } else {
                        // Handle other types of errors (e.g., network issues, server errors)
                        console.error('Unexpected error:', err);
                        this.showModal('An unexpected error occurred', [], "error");
                    }
                })
            }
        } else {
            this.showModal("Once leave is accepted you cannot change its status again.", [], "error")
        }
    }
    handleBulkApplications(type: string, data: any) {
        const leaveIds = data.map((item: any) => item.leave_request__id);
        let payload = {
            "data": data,
            "method": {
                "remarks": this.remarks,
                "status": this.status,
            }
        }
        if (data[0].leave_request__status !== "Accepted") {
            if (data[0].leave_request__id === null || data[0].leave_request__id === undefined) {
                HrmService.handleLeaveApplications(payload, type).then((response) => {
                    // console.log(response.data.result)
                    this.showModal("Succesfully created", [], "success");
                    this.closeSideBar()
                    this.retrieve("New")
                    this.searchSelected = "New"
                    this.selectAll = false
                }).catch((err) => {
                    if (err.response && err.response.status === 400) {
                        // Handle the 400 Bad Request error
                        this.showModal("", err.response.data, "errorArray");
                    } else {
                        // Handle other types of errors (e.g., network issues, server errors)
                        console.error('Unexpected error:', err);
                        this.showModal('An unexpected error occurred', [], "error");
                    }
                })
            }
            else if (data[0].leave_request__id !== null || data[0].leave_request__id !== undefined) {
                HrmService.updateLeaveApplications(payload, type, leaveIds).then((response) => {
                    // console.log(response.data.result)
                    this.showModal("Succesfully Updated", [], "success");
                    this.closeSideBar()
                    this.searchSelected = "New"
                    this.selectAll = false
                    this.retrieve("New")
                }).catch((err) => {
                    if (err.response && err.response.status === 400) {
                        // Handle the 400 Bad Request error
                        this.showModal("", err.response.data, "errorArray");
                    } else {
                        // Handle other types of errors (e.g., network issues, server errors)
                        console.error('Unexpected error:', err);
                        this.showModal('An unexpected error occurred', [], "error");
                    }
                })
            }
        } else {
            this.showModal("Once leave is accepted you cannot change its status again.", [], "error")
        }
    }
    getStatusClass(status: any) {
        if (status === 'Not Accepted') {
            return 'status-pill red';
        } else if (status === 'Accepted') {
            return 'status-pill green';
        } else if (status === 'Rejected') {
            return 'status-pill red';
        }
        else if (status === null) {
            return 'status-pill orange';
        }
        else {
            return 'status-pill'; // Default styling if status doesn't match any condition
        }
    }




}
