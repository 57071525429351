
import { Component, Vue, Prop } from "vue-property-decorator";
import HRMServices from '@/services/hrm-service'
import Utils from '@/utils/utils'
import PopUp from "@/components/PopUp.vue";
import { BvModal } from "bootstrap-vue";



@Component({
    components: {
        PopUp,
    },
})


export default class LeaveTypes extends Vue {
    @Prop({ default: 'types' }) activeState!: String;


    mounted() {
        this.retreive()
    }
    public currentPage = 1;

    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    // modal end


    fields = [
        { key: "title", sortable: true },
        { key: "no_of_leaves", sortable: true },
        { key: "leave_unit", sortable: true },
        { key: "day_count", sortable: true },
        { key: "calendar_type", sortable: true },
        { key: "is_special", label: "Special Leave" },
        { key: "nature", sortable: true },
        { key: "comments", sortable: true },
        { key: "allow_negative", sortable: true },
        { key: "reason_required", sortable: true },
        { key: "attachment_required", sortable: true },
        { key: "days_before_apply", sortable: true },
        { key: "allow_emergency", sortable: true },

    ]

    leave_settings: any = {
        title: "",
        no_of_leaves: "",
        calendar_type: "",
        nature: "",
        comments: "",
        leave_unit: "",
        day_count: "",
        allow_negative: false,
        reason_required: false,
        attachment_required: false,
        days_before_apply: 0,
        for_days: "",
        allow_emergency: false,
        is_special: false,
    };
    forUpdate = false
    change() {
        if (this.leave_settings.is_special == true) {
            this.leave_settings.nature = ""
        }
    }

    onRowClicked(data: any) {
        this.leave_settings = data

        this.forUpdate = true
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-leave");
    }
    items = [
    ];
    get rows() {
        return this.items.length;
    }
    calendar = ["Yearly", "Joined Date"];
    nature = ["Carry Forward / Adjust in Salary", "Vanish"];
    day_count = ["Work Day", "Calendar Day"];
    leave_unit = ["Hour", "Day"];
    error: any = ""


    create() {
        this.error = ""
        const error_list = Utils.getEmptyKeys(this.leave_settings, ['title', 'no_of_leaves', 'calendar_type', 'day_count', 'leave_unit', 'days_before_apply'])
        if (error_list.length > 0) {
            this.error = Utils.generateErrorMessage(error_list)
        }
        else {
            if (!this.forUpdate) {
                HRMServices.createLeaveType(this.leave_settings).then((response) => {
                    this.retreive()
                    this.forUpdate = false
                    this.clear()
                    this.closeSideBar()
                    this.showModal("Succesfully created", [], "success");

                }).catch((err) => {
                    if (err.response && err.response.status === 400) {
                        // Handle the 400 Bad Request error
                        this.showModal("", err.response.data, "errorArray");
                    } else {
                        // Handle other types of errors (e.g., network issues, server errors)
                        console.error('Unexpected error:', err);
                        this.showModal('An unexpected error occurred', [], "error");
                    }
                })
            } else {
                HRMServices.updateLeaveType(this.leave_settings, this.leave_settings.id).then((response) => {
                    this.retreive()
                    this.forUpdate = false
                    this.clear()
                    this.closeSideBar()
                    this.showModal("Succesfully updated", [], "success");

                }).catch((err) => {
                    if (err.response && err.response.status === 400) {
                        // Handle the 400 Bad Request error
                        this.showModal("", err.response.data, "errorArray");
                    } else {
                        // Handle other types of errors (e.g., network issues, server errors)
                        console.error('Unexpected error:', err);
                        this.showModal('An unexpected error occurred', [], "error");
                    }
                })
            }
        }


    }
    closeSideBar() {
        const leaveTypeSidebar = this.$refs.leaveTypeSidebar as BvModal | undefined;
        if (leaveTypeSidebar) {
            leaveTypeSidebar.hide("sidebar-variant-leave");
        }
    }

    retreive() {
        HRMServices.getLeaveType().then((response) => {
            this.items = response.data
        })
    }
    clear() {
        this.forUpdate = false
        this.leave_settings = {
            title: "",
            no_of_leaves: "",
            calendar_type: "",
            nature: "",
            comments: "",
            leave_unit: "",
            day_count: "",
            allow_negative: false,
            reason_required: false,
            attachment_required: false,
            days_before_apply: 0,
            for_days: "",
            allow_emergency: false,
            is_special: false,
        }
    }
}
